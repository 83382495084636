.actions {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 1000;

  .buttons {
    height: var(--app-actions-height);
    background-color: var(--ion-color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;

    .action {
      margin-right: 27.5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.top {
    top: 0;

    .buttons {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &.bottom {
    bottom: 0;

    .buttons {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  &.mobile {
    .buttons {
      width: 100%;
      border-radius: 0;
    }
  }
}
