.menuItem {
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    color: #ffffff;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
  }
}
