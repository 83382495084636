.meeting {
  max-width: 100vw;
  margin: auto;

  &.mobile.menuTopSpace {
    margin-top: var(--app-menu-height);
  }

  ion-grid {
    --ion-grid-padding: 0;

    ion-col {
      --ion-grid-column-padding: 0;
    }
  }
}
