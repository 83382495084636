$height: 100vh;
$content-height: 100vh;
$footer-height: calc(#{$height} - #{$content-height});

// FIXME These are the page transition animations but they don't account for the "leaving" element
//  This fadeIn animation would be fine if it didn't immediately replace the bottom/previous view
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { top: 100vh; }
  to { top: 0; }
}

@keyframes slideLeft {
  from { left: 100vw; }
  to { left: 0; }
}

.page {
  height: $height;
  //animation-duration: .5s;
  //animation-name: slideLeft;
  position: relative;

  .main {
    height: $content-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
      margin-top: 50px;
    }
  }

  .footer {
    height: $footer-height;
    background: var(--ion-color-primary);

    .background {
      width: 100%;
      height: 77px;
      margin-top: -1px; // SVG line fix
      transform: rotate(180deg);
    }
  }

  .copyright {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;

    .link {
      text-decoration: none;
      font-size: 0.975em;
      color: #fff;
    }

    .text {
      margin-top: 2px;
      font-size: 0.875em;
      color: #fff;
    }
  }

  &.flip {
    .main {
      background: var(--ion-color-primary);
    }

    .footer {
      background: initial;

      .background {
        transform: rotate(0deg);
      }
    }

    .copyright {
      .link {
        color: var(--ion-color-dark-tint);
      }

      .text {
        color: var(--ion-color-dark-tint);
      }
    }
  }

  &.small {
    .main {
      .content {
        width: 350px;
      }
    }
  }

  &.large {
    .main {
      height: $height;

      .content {
        width: 725px;
        max-height: $content-height;
      }
    }

    .footer {
      display: none;
    }

    .copyright {
      display: none;
    }
  }

  &.full {
    .main {
      height: $height;
      justify-content: start;
    }

    .content {
      width: 100vw;
      max-height: $height;
      margin-top: 0;
      //overflow: hidden;
    }

    .footer {
      display: none;
    }

    .copyright {
      display: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .page {
    &.large {
      .main {
        .content {
          width: 100%;
        }
      }
    }
  }
}
