@keyframes gradientFlash {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.skeleton {
  border-radius: 3px;
  background: linear-gradient(271deg, #f5f5f5, #e7e7e7);
  background-size: 400% 400%;
  animation: gradientFlash 2s ease infinite;

  &.round {
    border-radius: 50%;
  }
}
