$fullVideoBorder: 3px;
$squareVideoBorder: 2px;

.videoBlock {
  position: relative;
  //background: var(--ion-color-dark);
  background: transparent;

  &.full {
    //width: 100%;
    width: 100vw;
    height: 100vh;
    // FIXME This value depends on how many rows should be included - if 2 rows, this would be 50vh, 3 would be (100vh / 3) etc
    padding-bottom: calc(100vh - (2 * #{$fullVideoBorder}));
    border: $fullVideoBorder solid var(--ion-color-primary);

    video {
      //height: 100vh;
      height: 100%;
    }

    &.half_height {
      padding-bottom: calc((100vh / 2) - (2 * #{$fullVideoBorder}));
    }

    &.third_height {
      padding-bottom: calc((100vh / 3) - (2 * #{$fullVideoBorder}));
    }

    &.quarter_height {
      padding-bottom: calc((100vh / 4) - (2 * #{$fullVideoBorder}));
    }

    &.mobile {
      // padding-bottom: calc((100vw / (16/9)) - (2 * #{$fullVideoBorder}));
      padding-bottom: 100vh - (2 * #{$fullVideoBorder});
    }
  }

  &.default {
    position: absolute;
    background: transparent;
    //z-index: 100;
    video {
      position: absolute;
      width: 300px;
      height: 200px;
      bottom: 20px;
      right: 20px;
      border: $fullVideoBorder solid var(--ion-color-primary);
      object-fit: cover;
      background: #111111;
      // TODO May want an extra stroke in case the user has a black background on a black background
      //box-shadow: 0 0 3pt 2pt white;
    }
    &.mobile {
      overflow: hidden;
      video {
        // FIXME Seems like the border doesn't continue all the way around if we don't do slightly different height and width
        width: calc(100vw / 3.2);
        height: calc(100vw / 3.25);
        border-radius: 50%;
        -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
        bottom: 0;
        right: 0;
        position: relative;
        object-fit: cover;
      }
    }
  }

  &.square {
    width: 100%;
    padding-bottom: 100%;
    border: $squareVideoBorder solid #000;
  }

  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }
}
