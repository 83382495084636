:root {
  --ion-font-family: 'TeamMeet', sans-serif;

  --ion-color-primary: #17191d;
  --ion-color-primary-rgb: 41,41,204;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #17191d;
  --ion-color-primary-tint: #24262a;

  --ion-color-warning: #F8D90F;
  --ion-color-warning-rgb: 248,217,15;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #dabf0d;
  --ion-color-warning-tint: #f9dd27;

  --ion-color-danger: #FF3039;
  --ion-color-danger-rgb: 255,48,57;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e02a32;
  --ion-color-danger-tint: #ff454d;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /*--ion-color-base: #d5c290;*/
  --ion-color-success: #28a745;
  --ion-color-secondary: #d5c290;

  /* App level varaibles */
  --app-actions-height: 55px;
  --app-whiteboard-menu-top: 21px;
}
