.action {
  cursor: pointer;

  ion-icon {
    font-size: 1.5em;
    color: #ffffff;
    opacity: 0.5;
    display: flex;
    align-items: center;
  }

  &.active {
    ion-icon {
      opacity: 1;
    }
  }
}
