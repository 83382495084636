.menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);

  &.top {
    top: 0;
  }

  &.bottom {
    bottom: 0;
  }
}
