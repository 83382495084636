.brand {
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    max-width: 200px;
  }
  
  .tagline {
    margin-top: 10px;
  }

  &.invert {
    color: #ffffff;
  }
}
