.menuToggle {
  display: flex;
  justify-content: center;
  width: 70px;
  height: 18px;

  .notch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    background-color: var(--ion-color-primary);
    cursor: pointer;
    
    .icon {
      color: #fff;
      font-size: 0.825em;
      opacity: 0.6;
    }
  }
  
  &.top {
    border-top: 1px solid var(--ion-color-primary);
    margin-top: -2px;

    .notch {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  &.bottom {
    border-bottom: 1px solid var(--ion-color-primary);
    margin-bottom: -2px;

    .notch {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
  }
}
